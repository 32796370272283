import React, { lazy } from 'react';
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from 'components/layout'
const San = lazy(() => import('sections/hero/San.js'));
const PreciosSan = lazy(() => import('sections/services/PreciosSan.js'));
const BotonSanGeo = lazy(() => import('sections/services/BotonSanGeo.js'));
const BoxCol2GeoTrust = lazy(() => import('sections/hero/BoxCol2GeoTrust.js'));
const SelloSitelock = lazy(() => import('sections/about/SelloSitelock.js'));
const SeguridadInformatica = lazy(() => import('sections/about/SeguridadInformatica.js'));
const EspecSSLSan = lazy(() => import('sections/services/EspecSSLSan.js'));
const Llamenos = lazy(() => import('sections/hero/Llamenos.js'));
const FaqGeoTrust = lazy(() => import('../../sections/services/FaqGeotrust'));
const ContactCreative = lazy(() => import('sections/contact/ContactCreative.js'));
const Footer = lazy(() => import('sections/services/Footer.js'));
class Index extends React.Component {

  render() {
    
    
    return (
      <div>
        <Helmet>
          <title>Certificados SSL SAN/UCC Colombia: Asegura Múltiples dominios</title>
          <meta name="description" content="Protege múltiples dominios en Colombia con Certificados SSL SAN UCC. Asegura la seguridad de tus sitios web y aplicaciones con un cifrado sólido y confiable." />
        </Helmet>
        <Layout>
          <San />           
          <PreciosSan />          
          <EspecSSLSan />          
          <BoxCol2GeoTrust />
          <BotonSanGeo />          
          <SelloSitelock />
          <SeguridadInformatica />          
          <FaqGeoTrust />
          <BotonSanGeo />          
          <Llamenos />
          <ContactCreative />
          <Footer />
        </Layout>
      </div>
    )
  }
}

export default Index

export const creativeVideoTwoQuery = graphql`
  query {
    site {
      meta: siteMetadata {
        title
        description
      }
    }
  }
`